import { renderCustomerChat } from './components/CustomerChat';
renderCustomerChat();

import ReactOnRails from 'react-on-rails';
import { lazyHydrate, lazyHydrateNearScreen, noHydrate } from './utils/hydrate';

ReactOnRails.register({
  BrowserDetector: noHydrate,
  Message: lazyHydrate(() =>
    import(/* webpackChunkName: "Message" */ './components/Message')
  ),
  CouponBanner: lazyHydrate(() =>
    import(/* webpackChunkName: "CouponBanner" */ './components/CouponBanner')
  ),
  ProductSearch: lazyHydrate(() =>
    import(
      /* webpackChunkName: "ProductSearch" */ './components/Product/ProductSearch'
    )
  ),
  ProductCover: lazyHydrate(() =>
    import(
      /* webpackChunkName: "ProductCover" */ './components/Product/ProductCover'
    )
  ),
  ProductPromotion: lazyHydrate(() =>
    import(
      /* webpackChunkName: "ProductPromotion" */ './components/Product/ProductPromotion'
    )
  ),
  ProductSidebar: lazyHydrate(() =>
    import(
      /* webpackChunkName: "ProductSidebar" */ './components/Product/ProductSidebar'
    )
  ),
  ProductRecommend: lazyHydrate(() =>
    import(
      /* webpackChunkName: "ProductRecommend" */ './components/Product/ProductRecommend'
    )
  ),
  ProductOption: lazyHydrateNearScreen(() =>
    import(
      /* webpackChunkName: "ProductOption" */ './components/Product/ProductOption'
    )
  ),
  SocialMedia: lazyHydrate(() =>
    import(/* webpackChunkName: "SocialMedia" */ './components/SocialMedia')
  )
});
